export const OnLoanBanner = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        overflow: 'hidden',
      }}
    >
      <span
        style={{
          zIndex: 1,
          fontSize: '20px',
          top: 0,
          right: 0,
          transform:
            'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
          transformOrigin: 'bottom right',
          backgroundColor: '#0a3094',
          color: 'white',
          padding: '0.1em 2em',
          boxShadow: '0 0 3px rgb(0 0 0 / 30%)',
          position: 'absolute',
        }}
      >
        On Loan
      </span>
    </div>
  );
};
