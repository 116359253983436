import { isAddress } from '../utils/interact';

export const formatWallet = (walletAddress: string) => {
  if (!isAddress(walletAddress)) {
    // Do not attempt to format if is not valid
    return walletAddress;
  }

  return `${String(walletAddress).substring(0, 6)}...${String(
    walletAddress
  ).substring(38)}`;
};

// This is a poor man version of this but the most popular addresses
// end in .eth
export const isEnsAddress = (walletAddress: string) => {
  console.log(walletAddress);
  return walletAddress.endsWith('.eth');
}
