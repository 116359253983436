import { useEffect, useState } from 'react';
import Web3Modal, { getInjectedProviderName } from 'web3modal';
import WalletLink from 'walletlink';
import WalletConnect from '@walletconnect/web3-provider';

import {
  fetchNetworkAndEtherscanUri,
  fetchContract,
  getCurrentWalletConnected,
  connectModalWallet,
  checkTokensForOwner,
  checkLoanedTokensForOwner,
} from '../utils/interact';

import { formatWallet } from '../utils/wallets';

import { NetworkAndEtherscanUri, Status, StatusType } from '../config/types';
import { mainNetwork, mainnetEtherscanTxUri } from '../utils/constants';

import Modal from '../components/modal';
import Token from '../components/token';
import Header from '../components/header';
import Footer from '../components/footer';
import StatusMessage from '../components/status-message';
import Head from '../components/head';

const Profile = () => {
  const [walletAddress, setWallet] = useState('');
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | undefined>();
  const [ownedTokens, setOwnedTokens] = useState<string[] | undefined>([]);
  const [loanedOutTokens, setLoanedOutTokens] = useState<string[] | undefined>(
    []
  );
  const [areTokensLoading, setAreTokensLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [networkAndEtherscanUri, setNetworkAndEtherscanUri] =
    useState<NetworkAndEtherscanUri>({
      network: mainNetwork,
      etherscanUri: mainnetEtherscanTxUri,
    });
  const [status, setStatus] = useState<Status>({
    statusMessage: null,
    statusType: StatusType.none,
  });

  const infuraId = networkAndEtherscanUri.network.infuraProjectId;
  const chainId = networkAndEtherscanUri.network.chainId;

  const providerOptions = {
    walletconnect: {
      package: WalletConnect,
      options: {
        infuraId,
      },
    },
    walletlink: {
      package: WalletLink,
      options: {
        appName: 'Meta Angels Minting',
        chainId,
        infuraId,
      },
    },
  };

  const getNetworkAndEtherscanUri = async () => {
    const [network, etherscanUri] = await fetchNetworkAndEtherscanUri();
    setNetworkAndEtherscanUri({ network, etherscanUri });
  };

  const isWalletNotConnected = !(walletAddress?.length > 0);

  const connectWalletPressed = async () => {
    if (isWalletNotConnected) {
      try {
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions,
        });
        await connectModalWallet(web3Modal, null, true);
        await fetchContract();
        await getWallet();
      } catch (e: any) {
        setStatus({
          statusMessage: e.message,
          statusType: StatusType.warn,
        });
      }
    }
  };

  const getWallet = async () => {
    const status = await getCurrentWalletConnected();

    if (!status) {
      return;
    }

    setStatus({ statusMessage: status.status, statusType: status.type });
    if (status.address) {
      setWallet(status.address);
    }
  };

  const onPageLoad = async () => {
    await getNetworkAndEtherscanUri();

    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(web3Modal);

    if (web3Modal?.cachedProvider) {
      await connectModalWallet(web3Modal, getInjectedProviderName(), false);
    }

    if (window.ethereum) {
      await fetchContract();
      await getWallet();
    }
    setIsPageLoading(false);
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    const getTokens = async () => {
      if (walletAddress) {
        const tokens = await checkTokensForOwner(walletAddress);

        if (tokens) {
          setOwnedTokens(tokens);
        }

        const loanedOutTokens = await checkLoanedTokensForOwner(walletAddress);

        if (loanedOutTokens) {
          setLoanedOutTokens(loanedOutTokens);
        }

        setAreTokensLoading(false);

        // If we ever not get tokens something prob went wrong
        if (!tokens || !loanedOutTokens) {
          setStatus({
            statusMessage: (
              <p>Something went wrong while fetching some of your tokens. Contact us <a href="https://www.metaangelsnft.com/contact">here</a> if the problem persists.</p>
            ),
            statusType: StatusType.warn,
          });
        }
      }
    };

    getTokens();
  }, [walletAddress]);

  const hasTokens = ownedTokens?.length || loanedOutTokens?.length;

  return (
    <>
      <Head title={`My Meta Angels`} metaContent="My Meta Angels" />
      <div className="flex flex-col items-center h-screen">
        <div className="mb-10 w-full">
          <Header />
        </div>
        <div className="container mb-10">
          {isPageLoading ? (
            <Modal showLoader message={<div>Loading...</div>} />
          ) : (
            <>
              {status.statusMessage !== null && (
                <div className="mb-10">
                  <StatusMessage
                    statusMessage={status.statusMessage}
                    statusType={status.statusType}
                  />
                </div>
              )}
              <div>
                <h1 style={{ fontSize: '32px' }}>
                  Welcome to Your Member Page
                </h1>
              </div>
              <div>
                {isWalletNotConnected ? (
                  <div className="md:text-left text-center">
                    <button
                      onClick={connectWalletPressed}
                      className="btn-xxlarge mt-5"
                    >
                      Connect your Wallet
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="italic">
                      You are connected via wallet {formatWallet(walletAddress)}
                    </div>
                    <div className="mt-10">
                      {areTokensLoading ? (
                        <p>Loading...</p>
                      ) : !hasTokens ? (
                        <p className="text-center md:text-left">
                          This wallet does not hold any Meta Angels. Please
                          switch wallets if you hold Angels in another wallet or
                          head over to{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://opensea.io/collection/metaangelsnft"
                          >
                            the OpenSea collection
                          </a>{' '}
                          to purchase a token.
                        </p>
                      ) : (
                        <div>
                          <p>
                            Select an angel to download the high resolution
                            image, print via Level Frames, or manage lending.
                          </p>
                          <div className="justify-center md:justify-start flex flex-column flex-wrap">
                            {ownedTokens?.map((tokenId: string) => (
                              <div key={tokenId} className="my-5 md:mr-5">
                                <Token tokenId={tokenId} />
                              </div>
                            ))}
                            {loanedOutTokens?.map((tokenId: string) => (
                              <div key={tokenId} className="my-5 md:mr-5">
                                <Token tokenId={tokenId} onLoan />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
