import Link from 'next/link';
import Image from 'next/image';
import Loader from 'react-loader-spinner';
import { useQuery } from 'react-query';

import { ipfsToIpfsGateway } from '../utils/ipfs';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { OnLoanBanner } from './on-loan-banner';

const fetchToken = async ({ queryKey }: any) => {
  const res = await fetch(`/metadata/${queryKey}.json`);
  return res.json();
};

type TokenProps = {
  tokenId: string;
  onLoan?: boolean;
};

const Token = ({ tokenId, onLoan }: TokenProps) => {
  const { data, status: queryStatus } = useQuery(tokenId, fetchToken, {
    enabled: tokenId !== undefined,
  });

  const imgProps = {
    width: 315,
    height: 315,
    alt: `Meta Angel #${tokenId} | Meta Angels NFT`,
  };

  return (
    <div>
      {queryStatus === 'loading' ? (
        <Loader
          type="BallTriangle"
          timeout={300000}
          color="#08089a"
          height={50}
          width={50}
        />
      ) : (
        <Link passHref href={`/token/${tokenId}`}>
          <a
            style={{
              display: 'block',
              width: '315px',
              backgroundColor: 'white',
              borderRadius: '5px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {onLoan && <OnLoanBanner />}
            <img src={ipfsToIpfsGateway(data.image)} {...imgProps} />
            <div className="text-center p-5">{data?.name}</div>
          </a>
          
        </Link>
      )}
    </div>
  );
};

export default Token;
